@font-face {
    font-family: "icons";
    src: url("@parkour/assets/fonts/icons.woff2?bf3fb328a4bc212e505ed1c9e4671bb3") format("woff2");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-add-reaction-off-15-3-round:before {
    content: "\f101";
}
.icon-addpeople-off-15-3-round:before {
    content: "\f102";
}
.icon-ai-images-off-15-3-round:before {
    content: "\f103";
}
.icon-alert-off-15-3-round:before {
    content: "\f104";
}
.icon-alert-triangle-off-15-3-round:before {
    content: "\f105";
}
.icon-arrow-left-off-15-3-round:before {
    content: "\f106";
}
.icon-arrow-right-off-15-3-round:before {
    content: "\f107";
}
.icon-attachment-off-15-3-round:before {
    content: "\f108";
}
.icon-badge-on-15-3-round:before {
    content: "\f109";
}
.icon-block-off-15-3-round:before {
    content: "\f10a";
}
.icon-book-off-15-3-round:before {
    content: "\f10b";
}
.icon-bookmark-off-15-3-round:before {
    content: "\f10c";
}
.icon-bookmark-on-15-3-round:before {
    content: "\f10d";
}
.icon-broken-link-off-15-3-round:before {
    content: "\f10e";
}
.icon-bubble-off-15-3-round:before {
    content: "\f10f";
}
.icon-bubbles-off-15-3-round:before {
    content: "\f110";
}
.icon-calendar-add-off-15-3-round:before {
    content: "\f111";
}
.icon-calendar-off-15-3-round:before {
    content: "\f112";
}
.icon-calendar-video-off-15-3-round:before {
    content: "\f113";
}
.icon-calender-off-15-3-round:before {
    content: "\f114";
}
.icon-camera-off-15-3-round:before {
    content: "\f115";
}
.icon-check-off-15-3-round:before {
    content: "\f116";
}
.icon-chevron-bottom-off-15-3-round:before {
    content: "\f117";
}
.icon-chevron-down-off-15-3-round:before {
    content: "\f118";
}
.icon-chevron-left-off-15-3-round:before {
    content: "\f119";
}
.icon-chevron-right-off-15-3-round:before {
    content: "\f11a";
}
.icon-chevron-top-off-15-3-round:before {
    content: "\f11b";
}
.icon-circle-check-off-15-round:before {
    content: "\f11c";
}
.icon-clock-off-15-3-round:before {
    content: "\f11d";
}
.icon-clock-snooze-off-15-3-round:before {
    content: "\f11e";
}
.icon-close-circle-off-15-3-round:before {
    content: "\f11f";
}
.icon-cloud-download-off-15-3-round:before {
    content: "\f120";
}
.icon-compass-off-15-3-round:before {
    content: "\f121";
}
.icon-cookies-off-15-3-round:before {
    content: "\f122";
}
.icon-cross-off-15-3-round:before {
    content: "\f123";
}
.icon-disk-off-15-3-round:before {
    content: "\f124";
}
.icon-download-off-15-3-round:before {
    content: "\f125";
}
.icon-earth-off-15-3-round:before {
    content: "\f126";
}
.icon-edit-off-15-3-round:before {
    content: "\f127";
}
.icon-email-off-15-3-round:before {
    content: "\f128";
}
.icon-eye-off-off-15-3-round:before {
    content: "\f129";
}
.icon-file-off-15-3-round:before {
    content: "\f12a";
}
.icon-home-off-15-3-round:before {
    content: "\f12b";
}
.icon-images4-off-15-3-round:before {
    content: "\f12c";
}
.icon-info-circle-off-15-3-round:before {
    content: "\f12d";
}
.icon-life-buoy-off-15-3-round:before {
    content: "\f12e";
}
.icon-link3-off-15-3-round:before {
    content: "\f12f";
}
.icon-loader-off-15-3-round:before {
    content: "\f130";
}
.icon-lock-off-15-3-round:before {
    content: "\f131";
}
.icon-login-off-15-3-round:before {
    content: "\f132";
}
.icon-logout-off-15-3-round:before {
    content: "\f133";
}
.icon-menu-off-15-3-round:before {
    content: "\f134";
}
.icon-menu-simple-off-15-3-round:before {
    content: "\f135";
}
.icon-microphone-off-15-3-round:before {
    content: "\f136";
}
.icon-notifications-off-15-3-round:before {
    content: "\f137";
}
.icon-paper-plane-off-15-3-round:before {
    content: "\f138";
}
.icon-pause-off-15-3-round:before {
    content: "\f139";
}
.icon-people-blocked-off-15-3-round:before {
    content: "\f13a";
}
.icon-people-group-off-15-3-round:before {
    content: "\f13b";
}
.icon-people-off-15-3-round:before {
    content: "\f13c";
}
.icon-people-remove-off-15-3-round:before {
    content: "\f13d";
}
.icon-people2-off-15-3-round:before {
    content: "\f13e";
}
.icon-pin-off-15-3-round:before {
    content: "\f13f";
}
.icon-play-off-15-3-round:before {
    content: "\f140";
}
.icon-plus-circle-off-15-3-round:before {
    content: "\f141";
}
.icon-plus-off-15-3-round:before {
    content: "\f142";
}
.icon-qr-code-on-15-3-round:before {
    content: "\f143";
}
.icon-record-off-15-3-round:before {
    content: "\f144";
}
.icon-reply-off-15-3-round:before {
    content: "\f145";
}
.icon-search-off-15-3-round:before {
    content: "\f146";
}
.icon-settings-off-15-3-round:before {
    content: "\f147";
}
.icon-share-off-15-3-round:before {
    content: "\f148";
}
.icon-smile-off-15-3-round:before {
    content: "\f149";
}
.icon-sort2-off-15-3-round:before {
    content: "\f14a";
}
.icon-target-off-15-3-round:before {
    content: "\f14b";
}
.icon-telephone-off-15-3-round:before {
    content: "\f14c";
}
.icon-trash2-off-15-3-round:before {
    content: "\f14d";
}
.icon-video-camera-off-15-3-round:before {
    content: "\f14e";
}
